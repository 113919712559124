export const APP_NAME = 'MicroBlogBase';
export const APP_TITLE = 'MicroBlogBase';
export const SERVER_BASE_URI = 'https://microblogbase.herokuapp.com';
export const AUTH_API_URI = 'api/auth';
export const POSTS_API_URI = 'api/posts';
export const AUDIO_API_URI = 'api/audios';
export const DOMAIN_API_URI = 'api/domains';
export const PODCAST_API_URI = 'api/podcasts';
export const EPISODE_API_URI = 'api/episodes';
export const CONTRIBUTOR_API_URI = 'api/contributors';
export const NETWORK_API_URI = 'api/networks';
export const REGION_API_URI = 'api/regions';
export const INTERVIEW_API_URI = 'api/interviews';
