import axios from 'axios';
import { SERVER_BASE_URI } from '../utils/constants';

const request = axios.create({
   baseURL: SERVER_BASE_URI,
   headers: { access: 'same server' },
});

export default request;

export const privateRequest = axios.create({
   baseURL: SERVER_BASE_URI,
   headers: { 'Content-Type': 'application/json', access: 'same server' },
   withCredentials: true,
});
