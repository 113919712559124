import {
   createContext,
   useCallback,
   useEffect,
   useReducer,
   useRef,
} from 'react';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
   CheckCircleIcon,
   ExclamationCircleIcon,
   XCircleIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { v4 } from 'uuid';

export const TailwindNotificationContext = createContext();

export default function TailwindNotificationProvider({ children }) {
   const [notifications, dispatch] = useReducer((state, action) => {
      switch (action.type) {
         case 'NOTIFY':
            return [...state, { ...action.payload, id: v4() }];

         case 'REMOVE_NOTIFICATION':
            return state.filter((prev) => prev.id !== action.id);

         default:
            return state;
      }
   }, []);

   const notify = useCallback((payload) => {
      dispatch({ type: 'NOTIFY', payload });
   }, []);

   return (
      <TailwindNotificationContext.Provider value={notify}>
         <div
            aria-live='assertive'
            className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50'
         >
            <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
               {notifications.map((notification) => (
                  <Notification
                     key={notification.id}
                     {...notification}
                     dispatch={dispatch}
                  />
               ))}
            </div>
         </div>
         {children}
      </TailwindNotificationContext.Provider>
   );
}

function Notification({
   title,
   message,
   type = 'success',
   id,
   dispatch,
   element,
}) {
   const [show, setShow] = useState(true);
   const [perchantage, setPerchantage] = useState(0);
   const intervalId = useRef(null);

   const pauseTimer = () => {
      clearInterval(intervalId.current);
   };

   const startTimer = () => {
      intervalId.current = setInterval(() => {
         setPerchantage((prev) => {
            if (prev < 100) return prev + 0.5;
            clearInterval(intervalId.current);
            return prev;
         });
      }, 20);
   };

   const closeNotification = useCallback(() => {
      pauseTimer();
      setShow(false);
      setTimeout(() => {
         dispatch({ type: 'REMOVE_NOTIFICATION', id });
      }, 200);
   }, [dispatch, id]);

   useEffect(() => {
      if (perchantage >= 100) {
         closeNotification();
      }
   }, [perchantage, closeNotification]);

   const effectRan = useRef(true);

   useEffect(() => {
      if (effectRan.current) {
         startTimer();
      }
      return () => (effectRan.current = false);
   }, []);

   return (
      <Transition
         show={show}
         as={Fragment}
         enter='transform ease-out duration-300 transition'
         enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
         enterTo='translate-y-0 opacity-100 sm:translate-x-0'
         leave='transition ease-in duration-100'
         leaveFrom='opacity-100'
         leaveTo='opacity-0'
      >
         <div
            className='max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'
            onMouseEnter={pauseTimer}
            onMouseLeave={startTimer}
         >
            {/* <div
               style={{
                  width: `${perchantage}%`,
                  height: '3px',
                  background: 'black',
               }}
            /> */}
            <div className='p-4'>
               <div className='flex items-start'>
                  <div className='flex-shrink-0'>
                     {type.toLowerCase() === 'error' ? (
                        <XCircleIcon
                           className='h-6 w-6 text-red-600'
                           aria-hidden='true'
                        />
                     ) : type.toLowerCase() === 'info' ? (
                        <ExclamationCircleIcon
                           className='h-6 w-6 text-yellow-500'
                           aria-hidden='true'
                        />
                     ) : (
                        <CheckCircleIcon
                           className='h-6 w-6 text-green-400'
                           aria-hidden='true'
                        />
                     )}
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                     {title && (
                        <p className='text-sm font-medium text-gray-900'>
                           {title}
                        </p>
                     )}
                     {!element ? (
                        <p className='mt-1 text-sm text-gray-500'>{message}</p>
                     ) : (
                        element
                     )}
                  </div>
                  <div className='ml-4 flex-shrink-0 flex'>
                     <button
                        type='button'
                        className='bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={closeNotification}
                     >
                        <span className='sr-only'>Close</span>
                        <XIcon className='h-5 w-5' aria-hidden='true' />
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </Transition>
   );
}
