import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import TailwindNotificationProvider from './contexts/TailwindNotificationContext';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <HelmetProvider>
         <BrowserRouter>
            <TailwindNotificationProvider>
               <App />
            </TailwindNotificationProvider>
         </BrowserRouter>
      </HelmetProvider>
   </React.StrictMode>
);
