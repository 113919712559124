import { createContext, useCallback, useContext } from 'react';
import { EPISODE_API_URI, PODCAST_API_URI } from '../utils/constants';
import request from '../api/request';

const PodcastContext = createContext({});

export const PodcastProvider = ({ children }) => {
   const getHostedPodcast = useCallback(async () => {
      const res = await request.get(
         // `/${PODCAST_API_URI}/url?url=something2.com`
         `/${PODCAST_API_URI}/url?url=${window.location.hostname.toLowerCase()}`
      );
      return res.data;
   }, []);
   const getEpisode = useCallback(async (episodeId, { withAudios } = {}) => {
      const res = await request.get(
         `/${EPISODE_API_URI}/episode/${episodeId}`,
         { params: { withAudios: withAudios === true } }
      );
      return res.data;
   }, []);

   return (
      <PodcastContext.Provider
         value={{
            getHostedPodcast,
            getEpisode,
         }}
      >
         {children}
      </PodcastContext.Provider>
   );
};

export default PodcastContext;

export const usePodcastContext = () => useContext(PodcastContext);
