import { lazy, Suspense, useEffect, useState } from 'react';
import { PODCAST_API_URI } from './utils/constants';
import { PodcastProvider } from './contexts/PodcastContext';
import request from './api/request';
const AppRoutes = lazy(() => import('./AppRoutes'));
const PodcastRoutes = lazy(() => import('./PodcastRoutes'));

const App = () => {
   const [podcast, setPodcast] = useState({});

   useEffect(() => {
      const abortController = new AbortController();
      (async () => {
         try {
            const foundPodcast = await request.get(
               `/${PODCAST_API_URI}/url?url=${window.location.hostname.toLowerCase()}`,
               {
                  signal: abortController.signal,
               }
            );
            setPodcast(foundPodcast.data);
         } catch (err) {
            console.log('ERROR:', err);
            setPodcast(null);
         }
      })();
      return () => {
         abortController.abort();
      };
   }, []);

   return (
      <Suspense
         fallback={
            <div
               className='flex items-center justify-center w-full'
               style={{ height: '80vh' }}
            >
               <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
            </div>
         }
      >
         {podcast && Object.keys(podcast).length > 0 ? (
            <PodcastProvider>
               <PodcastRoutes podcast={podcast} />
            </PodcastProvider>
         ) : podcast == null ? (
            <AppRoutes />
         ) : (
            <div
               className='flex items-center justify-center w-full'
               style={{ height: '80vh' }}
            >
               <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
            </div>
         )}
      </Suspense>
   );
};

export default App;
